import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
//import { TranslatedLink } from '../../../../../components/TranslatedLink'
//import LinkBtn from '../../../../../components/linkBtn/LinkBtn'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Zamestnam extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Zamestnam.sk'}
          description={'Prípadová štúdia'}
          text={'Pre Zamestnam.sk sme vytvorili moderný pracovný portál. Pomáha nájsť vhodných kandidátov na pracovné pozície formou databázy, pokročilého vyhľadávania a množstva ďalších užitočných funkcií. Okrem toho sme pre klienta vytvorili moderné logo s firemnou identitou.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/zamestnamsk-branding' },
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php)', link: '/nase-prace/webstranky/portaly/zamestnamsk' },
          ]} />

        <AboutProject
          text={'Spolupráca s Zamestnam.sk prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Firemná identita'}
          description={'Pochopenie projektu, jeho zámeru a odborný prístup sú úspechom k plnohodnotnej firemnej identite.'}
          text={'Po konzultáciach s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal nový projekt prezentovať nie len designom, ale aj textami, fotkami a inými komunikačno-vizuálnymi kanálmi.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#3e3e69', tintColorCode: '#656587', nameColor: '#FFFFFF', name: 'Payne\'s grey' },
          { code: '#fdcf16', tintColorCode: '#fdd945', nameColor: '#FFFFFF', name: 'Mikado yellow' },
          { code: '#19191d', tintColorCode: '#47474a', nameColor: '#FFFFFF', name: 'Black' },
          { code: '#ffffff', tintColorCode: '#ffffff', nameColor: '#6B7B8A', name: 'White' },
        ]} />

        <FontShowcase
          fontName={'Ubuntu'}
          text={'The way typography is used says as much about our brand as the words themselves. <br/><br/> The Ubuntu typeface has been specially created to complement the Ubuntu tone of voice. It has a contemporary style and contains characteristics unique to the Ubuntu brand that convey a precise, reliable and free attitude.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/ubuntu.svg'}
        />

        <Reveal>
          <div className={'container p-0'}>
            <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        {/*<div className={'dark-section'}>
          <Reveal>
            <div className={'container'}>
              <div className={'row wrapper'}>
                <div className={'col-md-6'}>
                  <div className={'text-wrapper'}>
                    <h2 className={'cs-title'}>UX & Moderný web design</h2>
                    <p className="description">Nová profesionálna tvár Vášho webu</p>
                    <p className="description-smaller">Nový design stránky Zamestnam.sk zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.</p>
                    <LinkBtn>
                      <TranslatedLink to={'/nase-prace/webstranky/portaly/zamestnamsk'}>
                        Prejsť do detailu projektu
                      </TranslatedLink>
                    </LinkBtn>
                  </div>
                  <Img className={'img'} alt={this.constructor.name} fluid={data.pageOne.childImageSharp.fluid}/>
                  <Img className={'img'} alt={this.constructor.name} fluid={data.pageTwo.childImageSharp.fluid}/>
                  <Img className={'img'} alt={this.constructor.name} fluid={data.pageThree.childImageSharp.fluid}/>
                </div>
                <div className={'col-md-6'}>
                  <Img className={'img'} alt={this.constructor.name} fluid={data.pageFour.childImageSharp.fluid}/>
                  <Img className={'img'} alt={this.constructor.name} fluid={data.pageFive.childImageSharp.fluid}/>
                  <Img className={'img'} alt={this.constructor.name} fluid={data.pageSix.childImageSharp.fluid}/>
                </div>
              </div>
            </div>
          </Reveal>
        </div>*/}

        <PagesShowcase
          title={'UX & Moderný web design'}
          description={'Nová profesionálna tvár Vášho webu'}
          text={'Nový design stránky Zamestnam.sk zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/portaly/zamestnamsk'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageFour,
            },
            {
              name: 'O nás',
              image: data.pageOne,
            },
            {
              name: 'Výpis kandidátov',
              image: data.pageTwo,
            },
            {
              name: 'Blog',
              image: data.pageSix,
            },
            {
              name: 'FAQ',
              image: data.pageFive,
            },
            {
              name: 'Registrácia',
              image: data.pageThree,
            }
          ]} />

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="zamestnam" />
          </div>
        </Reveal>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Akostavat'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/portaly/ako-stavat/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/zamestnam/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Zamestnam_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/Zamestnam_pageOne.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/Zamestnam_pageTwo.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/Zamestnam_pageThree.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/Zamestnam_pageFour.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/Zamestnam_pageFive.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/Zamestnam_pageSix.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/Zamestnam_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/Zamestnam_corporate_identity.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Zamestnam
